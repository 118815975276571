<template>
  <div>
    <time-switcher @prev="previousYear" @next="nextYear">
      {{ format(currentDate, 'yyyy') }} <br />
      <span class="subdate"> year </span>
    </time-switcher>
    <div class="daily-view" v-if="!isLoading">
      <div class="table-container">
        <table class="table is-bordered is-fullwidth is-narrow is-hoverable">
          <tr>
            <th>User</th>
            <th>Absences (days)</th>
            <th>Sick</th>
            <th>Course/Conference</th>
            <th>Total unavailability</th>
          </tr>
          <user-row v-for="user in users" :key="user.id" :user="user" />
        </table>
      </div>
    </div>
    <div v-if="isLoading">
      <span class="icon-text">
        <span class="icon">
          <i class="fas fa-spin fa-sync-alt"></i>
        </span>
        <span>Loading...</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { useApi } from '@/api'
import { formatDate } from '@/utils'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addYears, format, parse } from 'date-fns'
import UserRow from '@/components/calendars/team-absences-user-row.vue'
import TimeSwitcher from '@/components/time-switcher.vue'

export default defineComponent({
  components: { TimeSwitcher, UserRow },
  setup() {
    /**
     * Current day state
     */
    const route = useRoute()
    const routeDate = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeDate)
        ? parse(routeDate, 'yyyy-MM-dd', new Date())
        : new Date()
    )

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.absences(formatDate(currentDate.value))
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * Routing
     */
    const router = useRouter()
    function nextYear() {
      router.push(
        '/absences-report/' + formatDate(addYears(currentDate.value, 1))
      )
    }

    function previousYear() {
      router.push(
        '/absences-report/' + formatDate(addYears(currentDate.value, -1))
      )
    }

    /**
     * Return
     */
    return {
      format,
      currentDate,
      nextYear,
      previousYear,
      users,
      isLoading
    }
  }
})
</script>

<style lang="scss" scoped>
.table-container {
  margin: 0 0 5px;
}
.timeline-row {
  width: 100%;
  padding: 0 !important;
  margin: 0;
}
.hours {
  display: flex;
  height: 35px;
}
.hour {
  width: 100%;
  height: 100%;
  border-right: 1px solid #dbdbdb;
  padding: 5px;

  &:last-child {
    border: none;
  }
}
.legend {
  text-align: right;

  &__item {
    display: inline-block;
    margin-left: 10px;
    padding: 2px 6px;
    font-size: 14px;

    &::before {
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 4px;
    }

    &--office::before {
      background-color: #7ce24d;
    }

    &--remote::before {
      background-color: #68ccf3;
    }
  }
}
</style>
