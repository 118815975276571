<template>
  <tr class="row">
    <user-in-table :user="user" />
    <td :title="absences">{{ absencesCount }}</td>
    <td :title="sicknesses">{{ sicknessesCount }}</td>
    <td :title="courses">{{ conferencesCount }}</td>
    <td title="Total year's absences">{{ allAbsencesCount }}</td>
  </tr>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { CreateDayDto, TimelineUserDto } from '@/../service/__generated-api'
import UserInTable from '@/components/user-in-table.vue'

export default defineComponent({
  components: { UserInTable },
  props: {
    user: {
      type: Object as PropType<TimelineUserDto>,
      required: true
    }
  },
  computed: {
    sicknessesCount(): number {
      return this.user.days.filter(day => day.sick).length
    },
    conferencesCount(): number {
      const isConference = (day: CreateDayDto) =>
        day.atConference && !day.halfNotAvailable
      return (
        this.user.days.filter(isConference).length +
        this.halfAbsencesInConferencesCount
      )
    },
    absencesCount(): number {
      const isAbsence = (day: CreateDayDto) =>
        (day.notAvailable || day.unplannedUnavailability) &&
        !day.makingUpUnavailability &&
        !day.halfNotAvailable &&
        !day.markedByPartTimeUser

      return this.user.days.filter(isAbsence).length + this.halfAbsencesCount
    },
    allAbsencesCount(): number {
      const isOverallAbsence = (day: CreateDayDto) =>
        !day.halfNotAvailable &&
        !day.makingUpUnavailability &&
        !day.markedByPartTimeUser
      const isOverallHalfAbsence = (day: CreateDayDto) =>
        day.halfNotAvailable && !day.makingUpUnavailability

      return (
        this.user.days.filter(isOverallAbsence).length +
        this.user.days.filter(isOverallHalfAbsence).length * 0.5
      )
    },
    halfAbsencesInConferencesCount(): number {
      const isHalfAbsenceInConference = (day: CreateDayDto) =>
        day.atConference && day.halfNotAvailable
      return this.user.days.filter(isHalfAbsenceInConference).length * 0.5
    },
    halfAbsencesCount(): number {
      const isHalfAbsence = (day: CreateDayDto) =>
        (day.notAvailable || day.unplannedUnavailability) &&
        day.halfNotAvailable &&
        !day.makingUpUnavailability
      return this.user.days.filter(isHalfAbsence).length * 0.5
    }
  },
  setup(props) {
    const dayAbsenceMessage = (day: CreateDayDto) => {
      if (day.notAvailableAccepted && day.halfNotAvailable) {
        return 'Accepted half-day'
      }
      if (!day.notAvailableAccepted && day.halfNotAvailable) {
        return 'Not accepted half-day'
      }
      if (day.notAvailableAccepted) {
        return 'Accepted'
      } else if (day.halfNotAvailable && day.unplannedUnavailability) {
        return 'Unplanned half-day'
      } else if (day.unplannedUnavailability) {
        return 'Unplanned'
      }
      return 'Not accepted'
    }
    const courseMessage = (day: CreateDayDto) => {
      const msg = 'On course/conference'
      return day.halfNotAvailable ? `${msg} half-day` : msg
    }
    const absences = props.user.days
      .filter(
        day =>
          (day.notAvailable || day.unplannedUnavailability) &&
          !day.makingUpUnavailability
      )
      .map(
        (day, index) =>
          `${index + 1}. ${day.formattedDate} - ${dayAbsenceMessage(day)}`
      )
      .join('\n')

    const courses = props.user.days
      .filter(day => day.atConference)
      .map(
        (day, index) =>
          `${index + 1}. ${day.formattedDate} - ${courseMessage(day)}`
      )
      .join('\n')

    const sicknesses = props.user.days
      .filter(day => day.sick)
      .map((day, index) => `${index + 1}. ${day.formattedDate} - Sick`)
      .join('\n')

    return {
      absences,
      sicknesses,
      courses
    }
  }
})
</script>
