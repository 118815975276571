<template>
  <div class="container">
    <div class="section">
      <h1 class="title">Absences report</h1>
      <hr />
      <team-absences-report />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import teamAbsencesReport from '@/components/calendars/team-absences.vue'
export default defineComponent({
  components: { teamAbsencesReport }
})
</script>
