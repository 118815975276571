
import { useApi } from '@/api'
import { formatDate } from '@/utils'
import { defineComponent, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { addYears, format, parse } from 'date-fns'
import UserRow from '@/components/calendars/team-absences-user-row.vue'
import TimeSwitcher from '@/components/time-switcher.vue'

export default defineComponent({
  components: { TimeSwitcher, UserRow },
  setup() {
    /**
     * Current day state
     */
    const route = useRoute()
    const routeDate = `${route.params.formattedDate}`
    const currentDate = ref(
      /^\d{4}-\d\d-\d\d$/.test(routeDate)
        ? parse(routeDate, 'yyyy-MM-dd', new Date())
        : new Date()
    )

    /**
     * Api call
     */
    const usersRes = useApi(api =>
      api.timeline.absences(formatDate(currentDate.value))
    )
    const isLoading = usersRes.isLoading
    const users = usersRes.data

    /**
     * Routing
     */
    const router = useRouter()
    function nextYear() {
      router.push(
        '/absences-report/' + formatDate(addYears(currentDate.value, 1))
      )
    }

    function previousYear() {
      router.push(
        '/absences-report/' + formatDate(addYears(currentDate.value, -1))
      )
    }

    /**
     * Return
     */
    return {
      format,
      currentDate,
      nextYear,
      previousYear,
      users,
      isLoading
    }
  }
})
